import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { background } from 'styled-system'

import { chevronDownGlyph, slidersGlyph, closeGlyph } from 'Assets/Svg'

import { Breadcrumbs } from 'Components/Blocks'

import {
  Button,
  Flex,
  Box,
  Text,
  Title,
  Icon,
  Loader as BaseLoader,
} from 'Components/UI'

import { CONTENT_WIDTH } from 'Constants/ids'

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  color: black;
  background-color: ${themeGet('colors.pearl')};

  @media screen and (max-width: 1100px) {
    #overlayShowOnMap {
      display: flex;
      border-radius: 0 0 ${themeGet('borderRadius.1')}${' '}${themeGet(
          'borderRadius.1',
        )};
    }
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    max-width: 100vw;
  }
`

export const SearchHolder = styled(Flex).attrs(() => ({
  mt: 0,
  p: [3, 3, 3, 0],
  flexDirection: ['column', 'column', 'column', 'row'],
}))`
  width: 100%;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    background-color: ${themeGet('colors.pearl')};
  }
`

export const SearchDatePickerHolder = styled(Flex)`
  width: 100%;
  max-width: 180px;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    max-width: 100%;
  }
`

export const Responsive = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'flex-start',
})`
  flex-grow: 1;
  width: 100%;
  max-width: ${CONTENT_WIDTH}px;
  background-color: ${themeGet('colors.pearl')};

  ${Title} {
    text-align: center;
  }

  > svg {
    fill: ${themeGet('colors.font.primary')};
    width: 200px;
    height: auto;
  }
`

export const Content = styled(Flex).attrs({
  mt: [0, 0, 0, 24],
  flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
})`
  color: black;
  width: 100%;
`

export const LeftSide = styled(Box).attrs({
  mr: [0, 0, 0, 4],
  display: ['none', 'none', 'block'],
})`
  width: 450px;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    width: 100%;
  }
`

export const MainContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 0;
`

export const FilterContainer = styled(Box)`
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};
  overflow: hidden;
  position: sticky;
  top: 75px; /* Header height */
`

export const ScrollContainer = styled(Box)`
  overflow-y: auto;
  background-color: ${themeGet('colors.white')};
  max-height: ${({ maxheight }) => maxheight || 'fit-content'};

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    max-height: calc(100vh - 120px);
  }
`

export const ChevronDown = styled(Icon).attrs({
  glyph: chevronDownGlyph,
  size: 12,
  ml: 2,
})`
  fill: ${themeGet('colors.tealBlue')};
`

export const DropdownText = styled(Text).attrs({
  fontSize: [14, 16, 18],
})`
  font-weight: 300;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.tealBlue')};
`

export const FilterHolder = styled(Box).attrs({
  fontSize: [14, 16, 18],
})`
  letter-spacing: 0.5px;
`

export const LoaderOverlay = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  min-height: 100px;
  width: 100%;
  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    margin-top: 50px;
  }
`

export const Loader = styled(BaseLoader)`
  width: 100%;
`

export const Row = styled(Flex).attrs({
  alignItems: 'center',
  flexDirection: 'column',
})`
  width: 100%;
`

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    display: none;
  }
`

export const MobileFiltersToggleContainer = styled(Flex)`
  cursor: pointer;
  font-weight: 600;
  width: fit-content;
  align-self: flex-start;
`

export const AdjustIcon = styled(Icon).attrs({
  glyph: slidersGlyph,
  size: 18,
  mr: 2,
})``

export const MobileSearchFieldsContainer = styled(Flex).attrs({
  pb: '12px',
  px: 3,
})`
  flex-direction: column;
  align-items: flex-start;
  background-color: ${themeGet('colors.white')};
  border-bottom: 1px solid ${themeGet('colors.lightGrey')};
`

export const MobileFiltersCountBadge = styled(Flex).attrs({})`
  display: block;
  padding: 0 6px;
  min-width: 20px;
  margin-left: 6px;

  border-radius: 50%;
  background-color: ${themeGet('colors.red')};

  font-size: 11px;
  text-align: center;
  font-weight: normal;
  color: ${themeGet('colors.white')};
`

export const SearchLabel = styled(Box).attrs({
  py: ['12px', '12px', '12px', 3],
})`
  font-size: 14px;
  font-weight: 700;
  color: ${themeGet('color.black')};
  line-height: 1;
`

export const MobileFiltersOverlay = styled(Flex).attrs({})`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 199;
  cursor: pointer;
`

const openMobileContainerCss = ({ open }) =>
  open &&
  css`
    transform: none;
    box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.3);
  `

export const MobileFiltersContainer = styled(Flex).attrs({
  flexDirection: 'column',
  width: ['100%', '100%', '100%', '400px'],
})`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;

  transform-origin: 0% 0%;
  transform: translate(-101%, 0);
  transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  background-color: ${themeGet(`colors.white`)};

  ${background};
  ${openMobileContainerCss};
`

export const MobileFiltersContainerTitle = styled(Box).attrs({
  px: 3,
  pt: 3,
})`
  font-size: 16px;
  font-weight: 700;
  color: ${themeGet('color.black')};
  line-height: 1;
  background-color: ${themeGet('colors.pearl')};
  position: relative;
`

export const CloseMobileFiltersIcon = styled(Icon).attrs({
  glyph: closeGlyph,
  size: 24,
})`
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
`

export const MobileFiltersResultsButtonContainer = styled(Box).attrs({
  px: 3,
  pt: 3,
  pb: 2,
})`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  text-align: center;

  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
`

const roundedborderButtonCss = ({ roundedborder }) =>
  roundedborder &&
  css`
    border-radius: ${themeGet('borderRadius.2')} !important;
    overflow: hidden;
  `

export const MobileFiltersShowResultsButton = styled(Button)`
  background-color: ${({ bg }) => bg || themeGet('colors.goldenrod')};
  border: 1px solid ${({ bg }) => bg || themeGet('colors.goldenrod')};
  border-radius: ${themeGet('borderRadius.1')};

  color: ${({ color }) => color || themeGet('colors.black')};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  width: 100%;
  min-width: 120px;
  white-space: nowrap;
  height: unset;
  padding: 12px;

  ${roundedborderButtonCss};
`
